import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';
import * as styles from './price.module.scss';
import { priceList } from '../../../../constants/trainings/price';

const Price = ({ onClick }) => {
	const intl = useIntl();
	return (
		<div className={styles.container}>
			<section className={styles.price}>
				<div className={styles.price__wrapper}>
					<div className={styles.price__title}>Что будет на пробном занятии?</div>
					<div className={styles.price__list}>
						{priceList.map(({ title, desc, icon }, index) => (
							<div className={styles.price__list__item} key={index}>
								<div className={styles.price__list__item__title}>
									<>{icon && <img src={icon} alt={title} />}</>
									<>{title}</>
								</div>
								<div className={styles.price__list__item__desc}>{desc}</div>
							</div>
						))}
					</div>
					<a
						href="https://forms.gle/esph9ncJ1XTGwFDn9"
						target="_blank"
						rel="nofollow"
						className={styles.button}
						onClick={() => {
							mixPanelService.sendEvent({
								event: 'Academy Landing / Click Buy Button',
								properties: {},
							});
						}}
					>
						Начать обучение бесплатно
					</a>
					<div className={styles.price__desc}>
						Бесплатно опробуй нашу учебную программу, сдай тест на скилл и познакомься с опытным тренером!
					</div>
				</div>
			</section>
		</div>
	);
};

export default Price;
