import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as styles from './loginPopup.module.scss';
import { useIntl, FormattedMessage } from 'gatsby-plugin-react-intl';
import useResponsive from '@src/utils/useResponsive';

import Steam from '@src/assets/images/landing/steam-v4.svg';
import Faceit from '@src/assets/images/landing/faceit-v4.svg';

import VideoImage from '/static/landing/popup/video.webp';
import PerformanceImageEn from '/static/landing/popup/performance-en.webp';
import PerformanceImageRu from '/static/landing/popup/performance-ru.webp';
import ReplayImageEn from '/static/landing/popup/replay-en.webp';
import ReplayImageRu from '/static/landing/popup/replay-ru.webp';

import { sendEvent } from '@src/utils/amplitude';
import { isBrowser } from '@src/utils/ssr';
import { getOperatingSystem } from '@src/utils/getOperatingSystem';
import UrlBuilder from '@src/utils/urlBuilder';

import { useSelector } from 'react-redux';
import { mixPanelService } from '../../../services/mixPanelService';

import crossIcon from '@src/assets/images/landing/cross-icon.svg';

const sources = {
	steam: {
		id: 'steam',
		sourceId: 'Steam',
	},
	faceit: {
		id: 'faceit',
		sourceId: 'Faceit',
	},
};

const LoginPopup = ({ isOpen, referrerId, onClickSignUp, onClose }) => {
	const d = useRef(null);

	const intl = useIntl();
	const account = useSelector((state) => state.account);
	const [isDesktop, isTablet, isMobile] = useResponsive();
	const [open, setOpen] = useState(false);
	const [steamLink, setSteamLink] = useState('');
	const [faceItLink, setFaceItLink] = useState('');
	const [showTooltip, setShowTooltip] = useState(false);
	const [error, setError] = useState(false);
	const [isMobileDescriptionVisible, setMobileDescriptionVisible] = useState(false);
	const [signUpUrl, setSignUpUrl] = useState('');

	const setUpLinks = () => {
		const appUrl = process.env.GATSBY_APP_URL;
		const backendUrl = process.env.GATSBY_API_URL;

		const os = getOperatingSystem();

		const steamLink = new UrlBuilder(`${backendUrl}/steamProfiles/auth`)
			.addParam('redirectUrl', `${appUrl}/auth-process`)
			.addParam('backendUrl', backendUrl);

		const faceItLink = new UrlBuilder(`${backendUrl}/faceIt/auth`)
			.addParam('successRedirectUrl', `${appUrl}/auth-process`)
			.addParam('failureRedirectUrl', `${appUrl}/auth-process`);

		const signUpLink = new UrlBuilder(`${appUrl}/signup`);

		if (referrerId) {
			steamLink.addParam('referrerId', referrerId);
			faceItLink.addParam('referrerId', referrerId);

			// MERGE CONFLICT
			if (process.env.GATSBY_ACTIVE_ENV === 'development') {
				const steamUrl = `${backendUrl}/steamProfiles/auth?redirectUrl=${`http://fron-398-referral.frontend.stats-staging.services`}/auth-process&referrerId=${referrerId}&backendUrl=${backendUrl}`;
				const faceItUrl = `${backendUrl}/faceIt/auth?successRedirectUrl=${`http://fron-398-referral.frontend.stats-staging.services`}/auth-process&referrerId=${referrerId}&failureRedirectUrl=${`http://fron-398-referral.frontend.stats-staging.services`}/auth-process`;

				setSteamLink(steamUrl);
				setFaceItLink(faceItUrl);

				return;
			}
		}

		// if (os === 'Windows') {
		// 	steamLink.addParam('viaSteamApp', true);
		// }

		setSteamLink(steamLink.build());
		setFaceItLink(faceItLink.build());
		setSignUpUrl(signUpLink.build());
	};

	useEffect(() => {
		if (isBrowser) {
			setUpLinks();
		}
	}, [referrerId]);

	useEffect(() => setOpen(isOpen), [isOpen]);

	useEffect(() => {
		if (!error) return;
		setTimeout(() => setError(false), 5000);
	}, [error]);

	useEffect(() => {
		const escKeyHandler = function (e) {
			if (e.key === 'Escape') {
				setOpen(false);
			}
		};

		if (open) {
			window.addEventListener('keydown', escKeyHandler);
		} else {
			if (onClose) onClose();
		}

		return () => window.removeEventListener('keydown', escKeyHandler);
	}, [open]);

	useEffect(() => {
		function handleScroll() {
			const innerScroll = document.getElementById('asd222');

			if (!innerScroll) return;

			const triggerElement = document.getElementById('triiger');
			const triggerRect = triggerElement.getBoundingClientRect();
			const clientHeight =
				window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
			const scrollDistanceFromTop = d.current.scrollTop + clientHeight - 214;

			const isVisible = scrollDistanceFromTop >= triggerRect.bottom;

			setMobileDescriptionVisible(isVisible);
		}

		if (d.current) {
			d.current.addEventListener('scroll', handleScroll);

			return () => {
				d.current.removeEventListener('scroll', handleScroll);
			};
		} else {
			console.error('Inner scroll element not found!');
		}
	}, []);

	const onAuthLinkClick = (selectedSource) => {
		logEvents(selectedSource);

		if (onClickSignUp) {
			onClickSignUp();
		}

		setError(false);
	};

	const logEvents = (selectedSource) => {
		sendEvent('New Landing / Sign Up / Click Source', {
			Source: sources[selectedSource].sourceId,
		});

		// mixPanelService.sendEvent(
		// 	{
		// 		event: 'Landing / Sign Up / Click Source',
		// 		properties: {
		// 			Source: sources[selectedSource].sourceId,
		// 		},
		// 	},
		// 	account?.userData?.steamAccountId || null
		// );

		mixPanelService.sendEvent(
			{
				event: 'Landing / Popup / Click Auth Button',
				properties: {
					Source: sources[selectedSource].sourceId,
				},
			},
			account?.userData?.steamAccountId || null
		);
	};

	return (
		<div
			className={[styles.container, open ? styles.open : ''].join(' ')}
			role="dialog"
			onClick={() => setShowTooltip(false)}
		>
			<div ref={d} className={styles.content} id="asd222">
				<button className={styles.close} onClick={() => setOpen(false)}>
					<span className={styles.closeIcon}></span>
					{intl.locale === 'ru' ? 'Закрыть' : 'Close'}
				</button>

				<button className={styles.mobileMenuClose} onClick={() => setOpen(false)}>
					<img src={crossIcon} alt="SCOPE.GG Cross Icon" />
					{intl.locale === 'ru' ? 'Закрыть' : 'Close'}
				</button>

				<div className={styles.titleWrapper}>
					{/* <span className={styles.title}>{intl.formatMessage({ id: 'landing.intro.loginPopup' })}</span> */}
					<div className={styles.actions}>
						<div className={styles.links}>
							<a href={steamLink} className={styles.link} onClick={() => onAuthLinkClick(sources.steam.id)}>
								<img src={Steam} alt="Steam logo" className={styles.icon} />
								{intl.locale === 'ru' ? 'войти через Steam' : 'Log in with Steam'}
							</a>

							<a
								href={faceItLink}
								className={`${styles.link} ${styles.link_faceit}`}
								onClick={() => onAuthLinkClick(sources.faceit.id)}
							>
								<img src={Faceit} alt="Faceit logo" className={styles.icon} />
								{intl.locale === 'ru' ? 'Войти через Faceit' : 'Log in with FaceIt'}
							</a>
						</div>

						{error ? (
							<div className={styles.error}>
								{intl.locale === 'ru'
									? 'Что-то пошло не так, попробуйте другой способ входа.'
									: 'Something went wrong, try another login method.'}
							</div>
						) : null}
					</div>

					<div className={styles.clipBlock}>
						<div className={styles.clipBlockVideo}>
							<video autoPlay muted loop>
								<source src="/video/first-screen/Clip.mp4" type="video/mp4" />
								<source src="/video/first-screen/Clip.webm" type="video/webm" />
							</video>
						</div>

						<div className={styles.clipBlockText}>
							<FormattedMessage
								id="landing.intro.clipBlockText"
								values={{
									multiKillText: (
										<span className={styles.coloredClipBlockText}>
											{intl.formatMessage({ id: 'landing.intro.clipBlockMultiKillText' })}
										</span>
									),
								}}
							/>
						</div>
					</div>

					<span className={styles.title}>{intl.formatMessage({ id: 'landing.intro.needAccount' })}</span>

					<a
						href={signUpUrl}
						className={[styles.link, styles.link_signup].join(' ')}
						onClick={() => {
							mixPanelService.sendEvent(
								{
									event: 'Landing / Click Sign Up Button',
									properties: {
										location: 'popup',
									},
								},
								account?.userData?.steamAccountId || null
							);
							if (onClickSignUp) {
								onClickSignUp();
							}
						}}
					>
						{intl.formatMessage({ id: 'landing.intro.signUp' })}
					</a>
				</div>

				{/*<div className={styles.cardsWrapper}>
					<div className={styles.cardWrapper}>
						<img src={VideoImage} className={styles.cardImage} />
						<div className={styles.cardBottom}>
							<div className={styles.cardTitleWrapper}>
								<span className={styles.cardTitle}>
									{intl.formatMessage({ id: 'landing.intro.loginPopupClipsTitle' })}
								</span>
							</div>
							<div className={styles.cardStepWrapper}>
								<span className={styles.cardStepText}>1</span>
							</div>
						</div>
					</div>

					<div className={styles.cardWrapper}>
						<img src={intl.locale === 'ru' ? PerformanceImageRu : PerformanceImageEn} className={styles.cardImage} />
						<div className={styles.cardBottom}>
							<div className={styles.cardTitleWrapper}>
								<span className={styles.cardTitle}>
									{intl.formatMessage({ id: 'landing.intro.loginPopupProgressTitle' })}
								</span>
							</div>
							<div className={styles.cardStepWrapper}>
								<span className={styles.cardStepText}>2</span>
							</div>
						</div>
					</div>

					<div className={styles.cardWrapper}>
						<img src={intl.locale === 'ru' ? ReplayImageRu : ReplayImageEn} className={styles.cardImage} />
						<div className={styles.cardBottom}>
							<div className={styles.cardTitleWrapper}>
								<span className={styles.cardTitle}>
									{intl.formatMessage({ id: 'landing.intro.loginPopupReplayTitle' })}
								</span>
							</div>
							<div id="triiger" className={styles.cardStepWrapper}>
								<span className={styles.cardStepText}>3</span>
							</div>
						</div>
					</div>
				</div>*/}

				<div className={styles.popupBottom}>
					{/*<div className={styles.popupDescriptionWraper}>
						<div className={styles.popupDescriptionText}>
							{intl.formatMessage({ id: 'landing.intro.loginPopupDescription' })}
						</div>
					</div>*/}
				</div>

				<div className={styles.mobilePopupBottom}>
					<div className={styles.mobileActions}>
						<div className={styles.links}>
							<a href={steamLink} className={styles.link} onClick={() => onAuthLinkClick(sources.steam.id)}>
								<img src={Steam} alt="Steam logo" className={styles.icon} />
								{intl.locale === 'ru' ? 'войти через Steam' : 'Log in with Steam'}
							</a>

							<a
								href={faceItLink}
								className={`${styles.link} ${styles.link_faceit}`}
								onClick={() => onAuthLinkClick(sources.faceit.id)}
							>
								<img src={Faceit} alt="Faceit logo" className={styles.icon} />
								{intl.locale === 'ru' ? 'Войти через Faceit' : 'Log in with FaceIt'}
							</a>
						</div>
						{error ? (
							<div className={styles.error}>
								{intl.locale === 'ru'
									? 'Что-то пошло не так, попробуйте другой способ входа.'
									: 'Something went wrong, try another login method.'}
							</div>
						) : null}
					</div>

					{/*<div className={[styles.mobilePopupDescriptionWraper, isMobileDescriptionVisible ? 'visible' : ''].join('')}>
						<div className={styles.mobilePopupDescriptionText}>
							{intl.formatMessage({ id: 'landing.intro.loginPopupDescription' })}
						</div>
					</div>*/}
				</div>
			</div>
		</div>
	);
};

export default LoginPopup;
